<template>
  <div>
    <div>
      <div class="title"><h3>اللائحه الموحده بالعقود و المشتريات</h3></div>
      <v-divider></v-divider>
      <v-container>
        <v-row>
          <h3 class="indigo--text">
            لتحميل اللائحة الموحدة للعقود والمشتريات أضغط هنا
            <a
              href="https://backend.ascww.org/api/tenders/download/standard-cnp-regulation"
            >
              <v-icon class="indigo--text">mdi-file</v-icon></a
            >
            <!--  <a
              :href="`${publicPath}` + './files/listOfContractandBuy.pdf'"
              download="listOfContractandBuy.pdf"
              ><v-icon class="indigo--text">mdi-file</v-icon></a
            > -->
          </h3>
        </v-row>
        <v-row class="mt-5">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
            <div>
              <div>
                <div id="app">
                  <v-col
                    class="subClass"
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="12"
                    md="12"
                  >
                    <PDFJSViewer
                      path="https://backend.ascww.org/api/tenders/file/standard-cnp-regulation"
                      :fileName="`${name}`"
                    />
                    <!--  <PDFJSViewer :path="`${path}`" :fileName="`${name}`" /> !-->
                  </v-col>
                </div>
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import PDFJSViewer from "../../components/PDFJSViewer.vue";
export default {
  components: {
    PDFJSViewer,
  },
  data() {
    return {
      name: "اللائحة الموحدة للعقود والمشتريات", //change which pdf file loads
      path: "lib/web/viewer.html", //path of the PDF.js viewer.html
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
h1 {
  color: #1f659b;
}
</style>
